import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "",
  error: null,
  result: null,
  createTransaction: {
    destination: "",
    token: "",
    tokenStandard: 3,
    tokenId: 0,
    value: 4,
  },
};

const createWalletSlice = createSlice({
  name: "createContract",
  initialState,
  reducers: {
    setDestination: (state, action) => {
      state.createTransaction.destination = action.payload;
    },
    setToken: (state, action) => {
      state.createTransaction.token = action.payload;
    },
    setTokenStandard: (state, action) => {
      state.createTransaction.tokenStandard = action.payload;
    },
    setTokenId: (state, action) => {
      state.createTransaction.tokenId = action.payload;
    },
    setValue: (state, action) => {
      state.createTransaction.value = action.payload;
    },
  },
});

export const {
  setDestination,
  setToken,
  setTokenStandard,
  setTokenId,
  setValue,
} = createWalletSlice.actions;

export default createWalletSlice.reducer;
