import "../App.scss";
import React from 'react'
import { useState } from "react";
import { Container, ButtonGroup, Button, Dropdown, DropdownButton, Nav , Navbar, Offcanvas} from 'react-bootstrap';
import Svg from './Svg'
import { useWeb3Helper } from "../infra/hooks/web3Helper";
import { useNavigate } from "react-router-dom";

function OffcanvasNav() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { login, loggedIn, logout } = useWeb3Helper();
  const navigate = useNavigate();
  const localLogIn = async () => {
    const result = await login();
    if(result){
      navigate(`/UserProfile`);
    }
  };
  return (
    <>
       <div className="d-none"> <Svg /></div>
    <Navbar fixed>
      <Container >
          <Navbar.Brand id="TozexLogo" href={process.env.PUBLIC_URL + "/"}>
            <div className="m-0">
              <svg  className="d-block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 116.68 64.5"
                height="60"
              >
                <g className="toz" fill="currentcolor">
                  <path d="M76.45,53.16l2.57-.4V35.09l-2.57-.34V34h15v4.79h-1L90,35.51a43.08,43.08,0,0,0-4.82-.21H81.93v7.82h5.41l.47-2.4h1v6.13h-1l-.47-2.42H81.91v8.18h3.94a33.43,33.43,0,0,0,5-.24l.86-3.71h1L92.43,54h-16Z" />
                  <path d="M99,52.75l2.48.4v.79H94.94v-.79l2.21-.4,6.82-9-5.81-8.62-2.26-.39V34h8.29v.79l-2.54.39,4.15,6.19,4.65-6.19-2.51-.39V34h6.56v.79l-2.21.39-5.64,7.49,6.9,10.12,2.27.4V54h-8.26v-.79l2.54-.4L104.84,45Z" />
                  <path d="M4.16,54V52.88l3.21-.4v-17H6.58a25.91,25.91,0,0,0-4.82.3L1.4,39.54H0V33.91H19.49v5.6H18.11l-.4-3.76a27.83,27.83,0,0,0-4.91-.26h-.73v17l3.18.41V54Z" />
                  <path d="M53.54,52.25,64.63,35.49H61.05a25.59,25.59,0,0,0-4.79.3l-.46,3.15H54.46v-5H69.93v1.6L58.79,52.4H63c1.14,0,2.26-.06,3.36-.17a21.25,21.25,0,0,0,2.38-.32l.88-3.83h1.35L70.54,54h-17Z" />
                  <g className="O">
                    <path d="M32.4,16.65,28.53,15a23.77,23.77,0,0,0-5.68,13.78l3.87,1.6A19.86,19.86,0,0,1,32.4,16.65Z" />
                    <path d="M66.56,30.5l3.84-1.6a23.75,23.75,0,0,0-5.67-13.79l-3.82,1.64A19.87,19.87,0,0,1,66.56,30.5Z" />
                    <path d="M26.68,30.84l-3.87,1.61a23.81,23.81,0,0,0,5.73,13.74l3.86-1.61A19.92,19.92,0,0,1,26.68,30.84Z" />
                    <path d="M60.63,16.42l1.61-3.87A23.77,23.77,0,0,0,48.4,6.75l-1.6,3.87A19.83,19.83,0,0,1,60.63,16.42Z" />
                    <path d="M46.4,10.66,44.77,6.75a23.82,23.82,0,0,0-13.7,5.73l1.6,3.87A19.88,19.88,0,0,1,46.4,10.66Z" />
                    <path d="M32.7,44.87l-1.6,3.88a23.76,23.76,0,0,0,13.64,5.67l1.6-3.87A19.84,19.84,0,0,1,32.7,44.87Z" />
                  </g>
                </g>
              </svg>
            </div>
          </Navbar.Brand>
          <div className="d-none d-lg-block">
            <ButtonGroup>
            <Button size="sm" variant="light" className="btn-4 text-uppercase"
                href={process.env.PUBLIC_URL + "/"}>
                  Home
                </Button>
                <Button className="btn-4 text-uppercase" size="sm" variant="light"
                href="#Pricing">
                  Pricing
                </Button>
              <DropdownButton variant="light" size="sm" className="btn-4" title="PRODUCT">
      <Dropdown.Item href="#Assets">Supported Assets & Networks</Dropdown.Item>
      <Dropdown.Item href="">API Documentation</Dropdown.Item>
    </DropdownButton>
              <Button
                variant="light"
                size="sm"
                className="btn-4 text-uppercase"
                href="#Faq">
               FAQ
              </Button>
              <Button
                variant="light"
                size="sm"
                className="btn-4 text-uppercase"
                href="#blog">
                Blog
              </Button>
              {!loggedIn ? (
              <Button
              variant="light"
              size="sm"
              className="btn-4 text-uppercase"
              onClick={() => localLogIn()}>
                login
            </Button>
            ) : (
              <Button
                variant="light"
                size="sm"
                className="btn-4 text-uppercase"
              href={process.env.PUBLIC_URL + "/Account"}>
              My Account
              </Button>
            )}
              
            </ButtonGroup>
          </div>
          <div className="menu d-lg-none" onClick={handleShow}>
            <svg viewBox="0 0 128 128" height="40">
              <rect
                fill="currentcolor"
                x="23.34"
                y="60.88"
                width="81.31"
                height="5"
              />
              <rect
                fill="currentcolor"
                x="23.34"
                y="93.38"
                width="81.31"
                height="5"
              />
              <rect
                fill="currentcolor"
                x="23.34"
                y="28.38"
                width="81.31"
                height="5"
              />
            </svg>
          </div>
          <Button
            variant="dark"
            size="sm"
            className="me-3 d-none"
            onClick={handleShow}
          >
            MENU
          </Button>
          <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="flex-sm-column">
                <Nav.Link href={process.env.PUBLIC_URL + "/"}>
                  <h4>Home</h4>
                </Nav.Link>
                <Nav.Link href="#Pricing">
                  <h4>Pricing</h4>
                </Nav.Link>
                <Nav.Link href="#Assets">
                  <h4>Product</h4>
                </Nav.Link>
                <Nav.Link href="#Faq">
                  <h4>FAQ</h4>
                </Nav.Link>
                <Nav.Link href="#Blog">
                  <h4>Blog</h4>
                </Nav.Link>
                <Nav.Link href={process.env.PUBLIC_URL + "/Account"}>
                  <h4>My Account</h4>
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Offcanvas>
        </Container>
      </Navbar>
      </>
  );
}

export default OffcanvasNav;
