import Accordion from "react-bootstrap/Accordion";

function TabSecurity() {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header variant="link">
          How does Multisig enhance security?
        </Accordion.Header>
        <Accordion.Body>
          Multisig enhances security by requiring multiple wallets to authorize
          transactions. This mitigates the risk associated with a single
          compromised key and ensures that important decisions are made
          collectively.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          What happens if a co signer wallet is compromised?
        </Accordion.Header>
        <Accordion.Body>
          If one key is compromised, the multisig setup remains secure as the
          attacker would still need additional authorized signatures to execute
          transactions. This reduces the impact of individual key compromises.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          What should I do if a co signer wallet is compromised?
        </Accordion.Header>
        <Accordion.Body>
          The co signer should immediatly update his wallet and ask co signers
          to confirm it rapidly.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          Is Tozex Multisig Vault audited by third party?
        </Accordion.Header>
        <Accordion.Body>
          Yes. We have recently audited it our last Multisig Vault version with
          Ominiscia.io you can check the report here.
          <br />
          The security remains our top priority.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default TabSecurity;
