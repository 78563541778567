import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import CreateSign from "./components/Multisig/CreateSign";
import SubmitTransaction from "./components/Multisig/SubmitTransaction";
import UserProfile from "./components/Multisig/UserProfile";
import Account from "./components/Multisig/AccountSettings";
import ResetPassword from "./components/SignUp/ResetPassword";
import SignUp from "./components/SignUp/SignUp";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./infra/store/store";
import reportWebVitals from "./reportWebVitals";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<App />}></Route>
          <Route path="/CreateSign" element={<CreateSign />}></Route>
          <Route
            path="/SubmitTransaction"
            element={<SubmitTransaction />}
          ></Route>
          <Route path="/UserProfile" element={<UserProfile />}></Route>
          <Route path="/ResetPassword" element={<ResetPassword />}></Route>
          <Route path="/SignUp" element={<SignUp />}></Route>
          <Route path="/Account" element={<Account />}></Route>
        </Routes>
      </Router>
    </Provider>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
