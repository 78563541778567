import React from "react";
import "../../App.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import NFTex from "../../media/NftEx1.png";

function ResponsiveTab() {
  return (
    <Container>
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th>Project</th>
              <th>Stablecoin</th>
              <th>Purchase Amount</th>
              <th>TxID</th>
              <th>Wallet</th>
              <th>Network</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <img className="me-2" src={NFTex} alt="NFT" height="40" />
                Artname #2862
              </td>
              <td>USDT</td>
              <td>100</td>
              <td>0x8b06b..79e5</td>
              <td>0xA91...a2E9</td>
              <td>Ethereum</td>
              <td>4:11am, 1 Jan 2022</td>
            </tr>
            <tr>
              <td>
                <img className="me-2" src={NFTex} alt="NFT" height="40" />
                Artname #2448
              </td>
              <td>BUSDT</td>
              <td>120</td>
              <td>0x8b06b..79e5</td>
              <td>0xA91...a2E9</td>
              <td>Ethereum</td>
              <td>4:11am, 1 Jan 2022</td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default ResponsiveTab;
