import { configureStore } from "@reduxjs/toolkit";

import userReducer from "../reduces/userReducer.js";
import useCreateContractHelper from "../reduces/createContractReducer.js";

import { contractsApi } from "../services/contracts.js";
import { transactionsApi } from "../services/transactions.js";

import { setupListeners } from "@reduxjs/toolkit/query";
import createTransactionReducer from "../reduces/createTransactionReducer.js";

const store = configureStore({
  reducer: {
    user: userReducer,
    contract: useCreateContractHelper,
    transaction: createTransactionReducer,
    [contractsApi.reducerPath]: contractsApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      contractsApi.middleware,
      transactionsApi.middleware
    ),
});

setupListeners(store.dispatch);

export default store;
