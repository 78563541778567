import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export const transactionsApi = createApi({
  reducerPath: "transactionsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: (args) => {
        const { contractAddress, network } = args;
        return {
          url: `/transactions`,
          params: { contractAddress, network },
        };
      },
    }),
    // getTransactionById: builder.query({
    //   query: (args) => {
    //     const { contractAddress: network } = args;
    //     return {
    //       url: `/contract`,
    //       params: {contractAddress: network},
    //     };

    //   }
    // }),
    createTransaction: builder.mutation({
      query: (body) => ({
        url: `/submit-transaction`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetTransactionsQuery,
  useGetTransactionByIdQuery,
  useCreateTransactionMutation,
} = transactionsApi;
