import React from 'react'
import {useState} from 'react'
import "../../App.scss";
import { Container, Row, Col, Button, Modal, Form, Stack } from 'react-bootstrap';
import ActionHistory from "./ActionHistoryTable";

function AccountSecurity(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
            <Col xs={12} md={10} lg={7} xl={6} className="mx-auto">
        <Row className="mt-4 justify-content-center">
            <Form>
                  <Form.Group className="mb-2" controlId="form">
                  <Form.Label>Email</Form.Label>
                    <Form.Control
                      className="me-auto"
                      placeholder="jane.cooper@gmail.com (Google)"
                    />
                    </Form.Group>
                   
                
                
                    <Stack className="mb-3" direction="horizontal">
                  
                  <div className='ms-auto '>
                  <Button className="mb-3 float-end btn-shadow-dark20 text-uppercase" variant="dark20">
                      Disconnect Google
                      
                    </Button></div>
                  </Stack>
                  
                    <Form.Group className="mb-2">
                      <Form.Label>Current password</Form.Label>
                      <Form.Control type="password"/>
                    </Form.Group>
                    <Stack className="mb-3" direction="horizontal">
                  <div className="alink">
                    <a href="#action1">Forgot password</a>
                    <svg className="ms-2" viewBox="0 0 48.08 40.9" height="14">
                      <polygon
                        fill="currentcolor"
                        points="26.09 0 22.68 3.66 38.04 17.95 0 17.95 0 22.94 38.05 22.95 22.68 37.24 26.09 40.9 48.08 20.45 26.09 0"
                      />
                    </svg>
                  </div>
                  <div className='ms-auto '>
                  <Button className="btn-shadow-dark20 text-uppercase" variant="dark20" onClick={() => setShow(true)}
      >
                    Change password
                  </Button></div>
                  </Stack>
                 
              </Form>
          </Row>
          </Col>
      <hr/>
      <Container>
        <div className="text-center">
          <h3 className="h3-responsive text-uppercase">Action history</h3>
        </div>
        <div className="mt-4">
          <ActionHistory />
        </div>
      </Container>
      <Modal
       show={show}
       onHide={() => setShow(false)}
       {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
                    <Form.Group className="mb-3" as={Col}>
                      <Form.Label>New password</Form.Label>
                      <Form.Control type="password" />
                    </Form.Group>
                    <Form.Group className="mb-3" as={Col}>
                      <Form.Label>Confirm new password</Form.Label>
                      <Form.Control type="password" />
                    </Form.Group>
                    </Modal.Body>
                    <Modal.Footer> <Button variant="light"  className="text-uppercase" onClick={handleClose}>
            Close
          </Button></Modal.Footer>
                    </Modal>
    </>
  );
}

export default AccountSecurity;
