const Signers = ({signers})=> {
  return (
    <div>
      {signers.map((signer, index) => (
        <div key={index}>
          <div>{signer}</div>
        </div>
      ))}
    </div>
  );
}
export default Signers;