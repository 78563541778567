import Accordion from "react-bootstrap/Accordion";

function TabGovernance() {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header variant="link">
          What is Multisig Governance?
        </Accordion.Header>
        <Accordion.Body>
          Multisig Governance refers to the collective decision-making process
          in a multisignature (multisig) vault, where multiple co signers must
          agree to authorize transactions or any rule changes.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How does Multisig Governance work?</Accordion.Header>
        <Accordion.Body>
          The owner set rules requiring a predefined number of signatures to
          validate transactions. <br />
          Decisions, such as fund transfers or changes to the wallet settings,
          are collectively made by obtaining the required number of signatures.{" "}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          Who is the owner of the Multisig Vault?
        </Accordion.Header>
        <Accordion.Body>
          The only priviledge is to transfert the ownership of the Multisig
          Vault to another party wihtout compromising the governance of it.
          <br />
          The owner can only submit a transaction to other co signers, he has no
          right to confirm any transactions.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>A co signer can change his wallet?</Accordion.Header>
        <Accordion.Body>
          Yes. Each co signer can issue a demand to update his wallet which must
          be accepted by the minimum of the predifined number of sinatures.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          Can I customize the multisignature vault's rules?
        </Accordion.Header>
        <Accordion.Body>
          Yes, multisignature vaults implemented as smart contracts allow for
          customization of rules and conditions, providing flexibility to align
          with specific organizational or regulatory requirements.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>Who is paying transaction gas fee?</Accordion.Header>
        <Accordion.Body>
          The co signers are paying to submit or confirm transaction. All
          agreements are recorded on chain to anchor it to the blockchain for
          transparency and liability reasons.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default TabGovernance;
