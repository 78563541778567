import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../reduces/userReducer";

export function useUserHelper(){

    const dispatch = useDispatch();

    const image = useSelector((state) => state.user.profile?.image);
    const name = useSelector((state) => state.user.profile?.name);
    const email = useSelector((state) => state.user.profile?.email);
    const address = useSelector((state) => state.user.address);
    const token = useSelector((state) => state.user.token);
    const setLocalToken = (token) => {
        dispatch(setToken(token));
    }
    return {image, name, email, address, token, setLocalToken};
}