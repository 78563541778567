import React from 'react'
import { Container, Button, Nav, Navbar, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useEffect, useState} from "react"
import './App.scss'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Navigation from './components/Nav'
import Footer from './components/Footer'
import Header from './components/Multisig/HeaderMultisig'
import Advantages from './components/Multisig/Advantages'
import Faq from './components/Multisig/Faq'
import Pricing from './components/Multisig/Pricing'
import Assets from "./components/Multisig/Assets";
import Blockchains from "./components/Multisig/Blockchains";

import { useWeb3Helper } from './infra/hooks/web3Helper';

function App() {
  const { login, loggedIn, logout } = useWeb3Helper();

  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || "theme-dark"
  );    
  const toggleTheme = () => {
  if (theme === 'theme-dark') {
  setTheme('theme-light');
  } else {
  setTheme('theme-dark');
  }
  };
  useEffect(() => {
  localStorage.setItem('theme', theme);
  document.body.className = theme;
  }, [theme]);

  return (
    <body className={`App ${theme}`} >
    <>
 
      {/* <Navbar>
        <Container className="first-navbar d-flex justify-content-end">
          <Nav className="">
          <OverlayTrigger
            overlay={(props) => (
              <Tooltip {...props}>
                Dark / Light
              </Tooltip>
            )}
            placement="bottom">
          <Button className="rounded-circle btn-switch me-3 mt-1" onClick={toggleTheme} ></Button>
          
          </OverlayTrigger>
          {!loggedIn ? (
                    <Button 
                      variant="dark"
                      size="sm"
                      onClick={() => login()}
                    >
                      Connect Wallet
                    </Button>
                ) : (
                    <Button
                     variant="dark"
                     size="sm"
                      onClick={() => logout()}
                    >
                      logout
                    </Button>
                )}
              <Nav.Link
                   href={process.env.PUBLIC_URL + "/Login"}>
                    SIGN IN
                  </Nav.Link>
                  </Nav>
        </Container>
      </Navbar> */}
      <Navigation/>
      <Header/>
      <Advantages/>
      <Assets/>
      <Blockchains/>
      <Pricing/>
      <Faq/>   
      <Footer/>
    </>
    </body>
  );
}

export default App;
