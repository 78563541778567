import React from "react";
import "../../App.scss";
import { useState } from "react";
import { Button, Row, Container, Col, Form, Card } from "react-bootstrap/";
import Nav from "../Nav";
import TopNav from "../TopNav";
import Footer from "../Footer";
import ConfirmTable from "./ConfirmTable";
import { Link } from "react-router-dom";
import { SubmitTx } from "./SubmitTx";
import useCreateContractHelper from "../../infra/hooks/useCreateContractHelper";

function Transaction(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { currentContract } = useCreateContractHelper();
  console.log(currentContract);
  // const { id } = useParams();
  // const {currentContract} = useGetContractByIdQuery(id);
  return (
    <>
      <TopNav />
      <Nav />
      <Container className="mt-4">
        <Row className="pb-">
          <Col xs={12} lg={6} className="mb-1" gap={3}>
            <div className="px-4 alink">
              <Link to={process.env.PUBLIC_URL + "/UserProfile"}>
                <svg
                  viewBox="0 0 48.08 40.9"
                  height="17"
                  className="ms-n2 me-3"
                >
                  <use href="#arrowL"></use>
                </svg>
                Back
              </Link>
              <Button size="sm" variant="">
                Import your token
              </Button>
              <Button size="sm" variant="">
                Invite co signers
              </Button>
              <Button size="sm" variant="">
                Update co signer
              </Button>
              <Button onClick={handleShow} size="sm" variant="">
                SubmitTransaction
              </Button>
            </div>
          </Col>
          <Col xs={12} lg={12}>
            {currentContract && (
              <Card className="m-3">
                <Card.Body className="text-start">
                  <Form>
                    <Row>
                      <Col>
                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column sm={4}>
                            MV Address
                          </Form.Label>
                          <Col
                            sm={8}
                            className="d-flex flex-row justify-content-end"
                          >
                            <Form.Control
                              className="text-end text-muted small"
                              plaintext
                              readOnly
                              defaultValue={currentContract?.contract_address}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column sm={4}>
                            Native Token Balance
                          </Form.Label>
                          <Col
                            sm={8}
                            className="d-flex flex-row justify-content-end"
                          >
                            <Form.Control
                              className="text-muted text-end"
                              plaintext
                              readOnly
                              defaultValue="0"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column sm={4}>
                            Blockchain network
                          </Form.Label>
                          <Col
                            sm={8}
                            className="d-flex flex-row justify-content-end"
                          >
                            <Form.Control
                              className="text-muted text-end"
                              plaintext
                              readOnly
                              defaultValue={currentContract?.network}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group as={Row} className="mb-2">
                          <Form.Label column sm={4}>
                            Required co signers
                          </Form.Label>
                          <Col
                            sm={8}
                            className="d-flex flex-row justify-content-end"
                          >
                            <Form.Control
                              className="text-muted text-end"
                              plaintext
                              readOnly
                              defaultValue={currentContract?.required}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-2  d-flex flex-row">
                          <Form.Label column sm={4} className="text-nowrap">
                            Co Signers
                          </Form.Label>
                          <Col sm={8}>
                            {currentContract?.signers.map((signer, index) => (
                              <div className="d-flex flex-row justify-content-end">
                                <Form.Control
                                  className="text-end text-muted small"
                                  plaintext
                                  readOnly
                                  defaultValue={signer}
                                />
                                <div className="alink d-flex flex-row justify-content-end">
                                  <a href="#action1" title="Email">
                                    <svg
                                      viewBox="0 0 127.56 127.56"
                                      height="20"
                                      className="ms-1"
                                    >
                                      <use href="#EmailStroke"></use>
                                    </svg>
                                  </a>
                                  <a href="#action1" title="Copy">
                                    <svg
                                      viewBox="0 0 127.56 127.56"
                                      height="20"
                                      className="ms-1"
                                    >
                                      <use href="#Copy"></use>
                                    </svg>
                                  </a>
                                  <a href="#action1" title="Settings">
                                    <svg
                                      viewBox="0 0 127.56 127.56"
                                      height="20"
                                      className="ms-1"
                                    >
                                      <use href="#SettingsStroke"></use>
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            ))}
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            )}
          </Col>
        </Row>
        <ConfirmTable currentContract={currentContract} />
      </Container>
      <SubmitTx show={show} handleClose={handleClose} />
      <Footer />
    </>
  );
}

export default Transaction;
