import React from "react";
import "../../App.scss";
import {
  Col,
  Row,
  Button,
  Form,
  Stack,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

function AccountProfil() {
  return (
    <>
      <Col xs={12} md={10} lg={7} xl={6} className="mx-auto">
        <Row className="mt-4 justify-content-center">
          <Card className="mb-3">
            <Card.Body>
              <Row className="text-center">
                <p>You have limitation until your identity is not verified</p>
                <hr />
              </Row>
              <Row className="justify-content-center">
                <div className="text-center">
                  <h4>Discover all functions of the platform</h4>
                </div>
                <div>
                  {" "}
                  <ul>
                    <li>1,000 EUR per NFT transaction</li>
                    <li>Invest in projects more than 1,000 EUR</li>
                    <li>
                      Create collection with total amount more than 10,000 EU
                    </li>
                  </ul>
                </div>
                <div className="text-center">
                  <Button className="btn-shadow-dark20" variant="dark20">
                    GET VERIFIED
                  </Button>
                </div>
              </Row>
            </Card.Body>
          </Card>
          <div className="mx-auto">
            <Stack direction="horizontal" className="my-3">
              <div>
                <svg viewBox="0 0 122.88 122.88" height="80">
                  <use href="#User"></use>
                </svg>
              </div>
              <div className="ms-n3 mt-4">
                <OverlayTrigger
                  overlay={(props) => (
                    <Tooltip {...props}>Upload an image</Tooltip>
                  )}
                >
                  <Button
                    variant="dark40"
                    size="sm"
                    className="rounded-circle p-1"
                  >
                    <svg viewBox="0 0 122.88 122.88" height="25">
                      <use href="#AddImage"></use>
                    </svg>
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="ms-3 ">
                {" "}
                <h5>Name</h5>
              </div>
            </Stack>
          </div>
          <Form>
            <div className="mb-3 text-dark40">Compte</div>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control placeholder="Jane Cooper" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Custom profile URL</Form.Label>
              <Form.Control
                type="email"
                placeholder="www.tozex.io/ Enter your short URL"
              />
            </Form.Group>
            <Form.Label>Status</Form.Label>
            <div className="ms-auto mb-3">
              <svg
                className="mb-1 me-2"
                viewBox="0 0 122.88 122.88"
                height="20"
              >
                <use href="#Cross"></use>
              </svg>
              NO KYC
            </div>
            <hr />
            <div className="mb-3 text-dark40">Social media</div>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                <svg viewBox="0 0 128 128" height="30">
                  <use href="#Globe"></use>
                </svg>
              </Form.Label>
              <Col sm="11" className="ps-3">
                {" "}
                <Form.Control
                  type="email"
                  placeholder="www.example.com*"
                />{" "}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                <svg viewBox="0 0 128 128" height="30">
                  <use href="#Github"></use>
                </svg>
              </Form.Label>
              <Col sm="11" className="ps-3">
                {" "}
                <Form.Control
                  type="email"
                  placeholder="www.github.com/example"
                />{" "}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                <svg viewBox="0 0 128 128" height="30">
                  <use href="#Twitter"></use>
                </svg>
              </Form.Label>
              <Col sm="11" className="ps-3">
                {" "}
                <Form.Control
                  type="email"
                  placeholder="www.twitter.com/example"
                />{" "}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                <svg viewBox="0 0 128 128" height="30">
                  <use href="#Telegram"></use>
                </svg>
              </Form.Label>
              <Col sm="11" className="ps-3">
                {" "}
                <Form.Control
                  type="email"
                  placeholder="www.telegram.com/example"
                />{" "}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                <svg viewBox="0 0 128 128" height="30">
                  <use href="#Linkedin"></use>
                </svg>
              </Form.Label>
              <Col sm="11" className="ps-3">
                {" "}
                <Form.Control
                  type="email"
                  placeholder="www.linkedin.com/example"
                />{" "}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                <svg viewBox="0 0 128 128" height="30">
                  <use href="#Discord"></use>
                </svg>
              </Form.Label>
              <Col sm="11" className="ps-3">
                {" "}
                <Form.Control
                  type="email"
                  placeholder="www.discord.com/example"
                />{" "}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                <svg viewBox="0 0 128 128" height="30">
                  <use href="#Tiktok"></use>
                </svg>
              </Form.Label>
              <Col sm="11" className="ps-3">
                {" "}
                <Form.Control
                  type="email"
                  placeholder="www.tiktok.com/example"
                />{" "}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                <svg viewBox="0 0 128 128" height="30">
                  <use href="#Instagram"></use>
                </svg>
              </Form.Label>
              <Col sm="11" className="ps-3">
                {" "}
                <Form.Control
                  type="email"
                  placeholder="www.instagram.com/example"
                />{" "}
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="1">
                <svg viewBox="0 0 128 128" height="30">
                  <use href="#Facebook"></use>
                </svg>
              </Form.Label>
              <Col sm="11" className="ps-3">
                {" "}
                <Form.Control
                  type="email"
                  placeholder="www.facebook.com/example"
                />{" "}
              </Col>
            </Form.Group>
          </Form>
        </Row>
        <hr />
        <Row className="pt-3">
          <div className="float-end">
            <Button className="btn-shadow-dark20 float-end" variant="dark20">
              LOG OUT
            </Button>
          </div>
        </Row>
      </Col>
    </>
  );
}

export default AccountProfil;
