import React from "react";
import "../../App.scss";
import Nav from "../Nav";
import TopNav from "../TopNav";
import Footer from "../Footer";
import MultiSigTable from "./MultiSigTable";

function UserProfile() {
  return (
    <>
      <TopNav />
      <Nav />
        <MultiSigTable />
      <Footer />
    </>
  );
}

export default UserProfile;
