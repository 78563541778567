import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    progress: false,
    token: "",
    profile: {
      aggregateVerifier: "",
      appState: "",
      dappShare: "",
      email: "",
      idToken: "",
      isMfaEnabled: false,
      name: "",
      oAuthAccessToken: "",
      oAuthIdToken: "",
      profileImage: "",
      typeOfLogin: "",
      verifier: "",
      verifierId: "",
    },
    address: "",
  },
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProgress, setAddress, setToken, setProfile } =
  userSlice.actions;

export default userSlice.reducer;
