import Accordion from "react-bootstrap/Accordion";

function TabGeneral() {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header variant="link">
          What's a Multisig Vault?
        </Accordion.Header>
        <Accordion.Body>
          A multisignature (Multisig) vault using a smart contract is a secure
          way to co manage and control digital assets, especially in the context
          of blockchain platforms like Ethereum. <br />
          Multisig require multiple wallets to authorize a transaction, adding
          an extra layer of security compared to traditional single-key wallets.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Why I should use it? </Accordion.Header>
        <Accordion.Body>
          Multisig vault distributes control and risk among authorized parties,
          ensuring collective decision-making, providing protection against key
          compromise and preventing unauthorized transfers reducing the risk of
          a single point of failure.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What is a co signer?</Accordion.Header>
        <Accordion.Body>
          A co signer is an authorized wallet allowing to submit or confirm an
          outside token transaction.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>How does it work?</Accordion.Header>
        <Accordion.Body>
          Co signers of the Multisig vault collectively manage funds by
          requiring a predefined number of wallet signatures to authorize
          transactions. Smart contracts programmatically enforce rules, and
          transaction history is transparently recorded on the blockchain.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default TabGeneral;
