import React, { useEffect } from "react";
import "../../App.scss";
import {
  Container,
  Row,
  Col,
  Table,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import Signers from "./Signers";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useGetContractsQuery } from "../../infra/services/contracts";
import useCreateContractHelper from "../../infra/hooks/useCreateContractHelper";
import { useSelector } from "react-redux";

function ResponsiveTab() {
  const { data, refetch } = useGetContractsQuery();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.user.token);
  const { setLocalCurrentContract } = useCreateContractHelper();
  const setCurrentContract = (wallet) => {
    setLocalCurrentContract(wallet);
    navigate("/submitTransaction/");
  };
  return (
    <>
      <Container className="mt-5">
        <Row className="py-2 d-flex flex-row">
          <Col>
            <div>
              <h3>Multisig Vault</h3>
            </div>
          </Col>
          <Col className="d-flex flex-row justify-content-end">
            <ButtonGroup className="float-end text-uppercase">
              <Link variant="multisig" to="/" className="me-3">
                IMPORT
              </Link>
              <Link
                variant="multisig"
                to={process.env.PUBLIC_URL + "/Createsign"}
              >
                Create
              </Link>
              {/* <Link variant="multisig"  to="/">
                    API access
                  </Link> */}
            </ButtonGroup>
          </Col>
        </Row>
        <Row className="py-4">
          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Co signers</th>
                <th>Total co signers</th>
                <th>Required co signers</th>
                <th>Vault wallet</th>
                <th>Network</th>
                <th>Creation date</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.contracts &&
                data?.contracts?.map((contract) => (
                  <tr>
                    <td>{contract.name}</td>
                    <td>
                      <Signers signers={contract.signers}></Signers>
                    </td>
                    <td>{contract.signers.length}</td>
                    <td>2</td>
                    <td>{contract.contract_address}</td>
                    <td>{contract.network}</td>
                    <td>{contract.createdAt}</td>
                    <td>
                      <div className="alink">
                        <a href="#action1" title="Copy link">
                          <svg viewBox="0 0 128 128" height="25">
                            <use href="#Copy"></use>
                          </svg>
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="alink">
                        {/* <Link to={`/SubmitTransaction/${contract.id}`}> */}
                        <button onClick={() => setCurrentContract(contract)} style={{ border: 'none', background: 'none', padding: '0', margin: '0', textDecoration: 'underline', cursor: 'pointer' }}>
                          <svg viewBox="0 0 128 128" height="25">
                            <use href="#Link"></use>
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Row>
      </Container>
    </>
  );
}

export default ResponsiveTab;
