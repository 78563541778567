
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TabGeneral from "./TabGeneral";
import TabGovernance from "./TabGovernance";
import TabSecurity from "./TabSecurity";
import TabInteroperability from "./TabInteroperability";

function SectionQuestions() {
  return (
    <>

    <section className="flex-column d-flex justify-content-center anchor" id="Faq">
   <Container fluid className="my-5 ">
     <Row className="text-center">
          <h1 className="h1-responsive text-uppercase">
            Frequently Asked<span className="bold"> Questions</span>
          </h1>
        </Row>
          <Row className="my-5 flex-row d-flex justify-content-center">
           
            <Col xs={12} lg={8} id="ask">
              <Tabs defaultActiveKey="about" id="ask" className="mb-3 text-multi40">
                <Tab eventKey="about" title="General">
                  <TabGeneral />
                </Tab>
                <Tab eventKey="token" title="Governance">
                  <TabGovernance />
                </Tab>
                <Tab eventKey="trading" title="Security">
                  <TabSecurity/>
                </Tab>
                <Tab eventKey="Bridge" title="Interoperability">
                  <TabInteroperability/>
                </Tab>
              </Tabs>
            </Col>
          </Row>
      </Container>
      </section>
    </>
  );
}

export default SectionQuestions;
