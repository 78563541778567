import Accordion from "react-bootstrap/Accordion";

function TabInteroperability() {
  return (
    <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header variant="link">
          Which type of Blockchain networks the Multisig Vault is compatible
          with?
        </Accordion.Header>
        <Accordion.Body>
          All EVM Blockchain networks are compatible with our solution, but only
          major networks are proposed on our Free plan. To add a specific
          network contact us.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What’s the need to propose an API?</Accordion.Header>
        <Accordion.Body>
          Major companies are running their own IT system (marketplace,
          exchange, e-commerce …) and they need to use Multisig Vault to secure
          and co-manage their digital assets automatically and safely.
          <br />
          Example: You are a financial platform holding funds, you want to be
          able to plug your platform to automatically manage the funds on it
          with your team, founders, partners or even users.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          What’s transaction rate limit per minute?
        </Accordion.Header>
        <Accordion.Body>
          We are restraining the number of transactions submitted per minute for
          security reasons. This limit is customized with our Enterprise plan
          selected.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What’s Gas limit per transaction? </Accordion.Header>
        <Accordion.Body>
          We limit the gas fee to 80 Gwei to prevent our clients to pay
          expensive and non-controlled fee when the blockchain network is
          congested. Such parameter still customizable by the client with the
          Enterprise plan.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default TabInteroperability;
