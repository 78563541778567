import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "./baseQuery";

export const contractsApi = createApi({
  reducerPath: "contractsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getContracts: builder.query({
      query: (network = "ethereum_sepolia") => `/contracts?network=${network}`,
    }),
    getContractById: builder.query({
      query: (id) => `/contracts/${id}`,
    }),
    createContract: builder.mutation({
      query: (body) => ({
        url: `/deploy`,
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useGetContractsQuery,
  useGetContractByIdQuery,
  useCreateContractMutation,
} = contractsApi;
