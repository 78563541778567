import React from "react";
import "../../App.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function Advantages(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Container fluid className="bg-multisig text-white FondLogos">
        <Container className="py-5">
          <Row className="pt-3">
            <Col md={6} lg={3} className="text-center px-lg-5">
              <svg
                data-aos="zoom-in"
                data-aos-duration="1200"
                data-aos-delay="1"
                data-aos-easing="ease-in-out"
                viewBox="0 0 64 64"
                height="60"
                className="mb-2"
              >
                <use href="#API"></use>
              </svg>
              <p>Dedicated API to manage your Multisig Vaults</p>
            </Col>
            <Col md={6} lg={3} className="text-center px-lg-5">
              <svg
                data-aos="zoom-in"
                data-aos-duration="1200"
                data-aos-delay="1"
                data-aos-easing="ease-in-out"
                viewBox="0 0 64 64"
                height="60"
                className="mb-2"
              >
                <use href="#Distributed"></use>
              </svg>
              <p>
                Distributed governance access to co-manage your tokens with up
                to 10 co signers.
              </p>
            </Col>
            <Col md={6} lg={3} className="text-center px-lg-5">
              <svg
                data-aos="zoom-in"
                data-aos-duration="1200"
                data-aos-delay="1"
                data-aos-easing="ease-in-out"
                viewBox="0 0 64 64"
                height="60"
                className="mb-2"
              >
                <use href="#ManageToken"></use>
              </svg>
              <p>
                Compatible with all categories of tokens (Native, Fungible, NFT,
                Stabelcoin & RWA).
              </p>
            </Col>
            <Col md={6} lg={3} className="text-center px-lg-5">
              <svg
                data-aos="zoom-in"
                data-aos-duration="1200"
                data-aos-delay="1"
                data-aos-easing="ease-in-out"
                viewBox="0 0 64 64"
                height="55"
                className="mb-2"
              >
                <use href="#Compatible"></use>
              </svg>
              <p>Token Standards supported ERC20, 721 & 1155</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default Advantages;
