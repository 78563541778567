import React from "react";
import "../../App.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

function ResponsiveTab() {
  return (
    <Container>
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th>Project</th>
              <th>Invest amount</th>
              <th>TxID</th>
              <th>Wallet</th>
              <th>Network</th>
              <th>Token amount</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Project name</td>
              <td> 100</td>
              <td>0x8b06b..79e5</td>
              <td>0xA91...a2E9</td>
              <td>Ethereum</td>
              <td>
                1000 DRM
                <br />
                <small>1DRM=$0.1</small>
              </td>
              <td>4:11am, 1 Jan 2022</td>
            </tr>
            <tr>
              <td>Project name</td>
              <td> 100</td>
              <td>0x8b06b..79e5</td>
              <td>0xA91...a2E9</td>
              <td>Ethereum</td>
              <td>
                1000 DRM
                <br />
                <small>1DRM=$0.1</small>
              </td>
              <td>4:11am, 1 Jan 2022</td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default ResponsiveTab;
