import React from "react";
import "../../App.scss";
import { Container, Row, Button, Table } from "react-bootstrap";
import { useGetTransactionsQuery } from "../../infra/services/transactions";
import { useWeb3Helper } from "../../infra/hooks/web3Helper";

function ResponsiveTab({ currentContract }) {
  const { data, error, isLoading } = useGetTransactionsQuery({
    contractAddress: currentContract?.contract_address,
    network: currentContract?.network,
  });
  const { confirmTransaction } = useWeb3Helper();
  const invokeConfirmTransaction = async (contractAddress, transactionId) => {
    const tx = await confirmTransaction(contractAddress, transactionId);
    console.log(tx);
  };
  return (
    <Container>
      <div className="text-center my-3">
        <h5 className="py-5 h1-responsive text-uppercase">
          Confirm transactions
        </h5>
      </div>
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th>TxID</th>
              <th>Destination</th>
              <th>executed</th>
              <th>Amount</th>
              <th>Status</th>
              <th>confirmTimestamp</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan="9">Loading...</td>
              </tr>
            )}
            {error && (
              <tr>
                <td colSpan="9">Error: {JSON.stringify(error)}</td>
              </tr>
            )}
            {data &&
              data?.body?.transactions.length > 0 &&
              data?.body?.transactions?.map((transaction) => (
                <tr key={transaction?.id}>
                  <td>{transaction?.id}</td>
                  <td>{transaction?.destination}</td>
                  <td>{transaction?.executed?.toString()}</td>
                  <td>{transaction?.value}</td>
                  <td>{transaction?.executed ? "completed" : "pending"}</td>
                  <td>{transaction?.confirmTimestamp}</td>
                  <td>
                    <Button
                      onClick={() =>
                        invokeConfirmTransaction(
                          currentContract?.contract_address,
                          transaction?.id
                        )
                      }
                      variant="multisig"
                      size="sm"
                      className="ms-3 text-uppercase"
                    >
                      Confirm
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default ResponsiveTab;
