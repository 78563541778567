import {
  setName,
  setSigner,
  addSigner,
  setNetwork,
  setCurrentContract,
  removeSigner,
  setRequired,
} from "../reduces/createContractReducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const useCreateWalletHelper = () => {
  const name = useSelector((state) => state.contract.createContract.name);
  const createWalletObj = useSelector((state) => state.contract.createContract);
  const network = useSelector((state) => state.contract.createContract.network);
  const signers = useSelector((state) => state.contract.createContract.signers);
  const required = useSelector(
    (state) => state.contract.createContract.required
  );
  const currentContract = useSelector(
    (state) => state.contract.currentContract
  );
  const dispatch = useDispatch();
  const setLocalSigner = (index, value) => {
    dispatch(setSigner({ index, value }));
  };
  const addLocalSigner = () => {
    dispatch(addSigner());
  };
  const removeLocalSigner = (index) => {
    dispatch(removeSigner(index));
  };
  const setLocalRequired = (value) => {
    dispatch(setRequired(parseInt(value)));
  };
  const setLocalNetwork = (value) => {
    dispatch(setNetwork(value));
  };
  const setLocalName = (value) => {
    dispatch(setName(value));
  };
  const setLocalCurrentContract = (value) => {
    dispatch(setCurrentContract(value));
  };

  return {
    name,
    network,
    signers,
    required,
    createWalletObj,
    currentContract,
    setLocalName,
    setLocalSigner,
    addLocalSigner,
    removeLocalSigner,
    setLocalRequired,
    setLocalNetwork,
    setLocalCurrentContract,
  };
};

export default useCreateWalletHelper;
