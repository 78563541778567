import { useEffect, useState } from "react";
import { Web3Auth } from "@web3auth/modal";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";

import { MetamaskAdapter } from "@web3auth/metamask-adapter";

import Web3 from "web3";

import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { useDispatch } from "react-redux";
import { setAddress, setProfile } from "../reduces/userReducer";
import { MultisigAbi } from "../constants/MultisigAbi";
import { useUserHelper } from "./userUserHelper";
import { saveAccessToken } from "../utils";

const clientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID;

const chainConfig = {
  chainId: "0xAA36A7", // Please use 0x1 for Mainnet
  rpcTarget: "https://rpc.ankr.com/eth_sepolia",
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  displayName: "Sepolia Ethereum",
  blockExplorerUrl: "https://etherscan.io/",
  ticker: "ETH",
  tickerName: "Ethereum",
  logo: "https://images.toruswallet.io/eth.svg",
};

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig: chainConfig },
});

const web3auth = new Web3Auth({
  clientId,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
  privateKeyProvider: privateKeyProvider,
});

const metamaskAdapter = new MetamaskAdapter({
  clientId,
  sessionTime: 3600, // 1 hour in seconds
  web3AuthNetwork: "sepolia_testnet",
  chainConfig: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0xAA36A7",
    rpcTarget: "https://rpc.ankr.com/eth_sepolia", // This is the public RPC we have added, please pass on your own endpoint while creating an app
  },
});

// it will add/update  the metamask adapter in to web3auth class
web3auth.configureAdapter(metamaskAdapter);

// subscribe to lifecycle events emitted by web3auth

export function useWeb3Helper() {
  const [provider, setProvider] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [web3, setWeb3] = useState(null);
  const dispatch = useDispatch();

  const { setLocalToken } = useUserHelper();
  // hack should be removed
  useEffect(() => {
    const init = async () => {
      try {
        if (web3auth.connected) {
          setLoggedIn(true);
          await getUserInfo();
          await getAccounts(web3auth.provider);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        await web3auth.initModal();
        setProvider(web3auth.provider);

        if (web3auth.connected) {
          setLoggedIn(true);
          await getUserInfo();
          await getAccounts(web3auth.provider);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  const login = async () => {
    try {
      const web3authProvider = await web3auth.connect();
      console.error(web3authProvider);
      if (web3authProvider) {
        if (web3auth.connected) {
          setLoggedIn(true);
        }
        setProvider(web3authProvider);
        await getUserInfo();
        await getAccounts(web3authProvider);
      }
      return true
    } catch (error) {
      setLoggedIn(false);
      console.error(error);
      return false;
    }
  };
  const getUserInfo = async () => {
    const user = await web3auth.getUserInfo();
    if (user?.idToken != null) {
      const base64Url = user?.idToken?.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const parsedToken = JSON.parse(window.atob(base64));
      console.log(parsedToken.wallets[0].public_key);
      console.log(user);
    }
    setLocalToken(process.env.REACT_APP_API_KEY); //TODO: replace with token from API
    saveAccessToken(process.env.REACT_APP_API_KEY);
    dispatch(setProfile(user));
  };
  const getAccounts = async (web3authProvider) => {
    if (!web3authProvider) {
      console.log("provider not initialized yet");
      return;
    }
    const web3 = new Web3(web3authProvider);
    setWeb3(web3);
    // Get user's Ethereum public address
    const address = await web3.eth.getAccounts();
    dispatch(setAddress(address[0]));
  };
  const confirmTransaction = async (contractAddress, transactionId) => {
    try {
      // Create a new instance of the MultisigData contract
      const multisigData = new web3.eth.Contract(MultisigAbi, contractAddress);
      const address = await web3.eth.getAccounts();
      dispatch(setAddress(address[0]));
      // Confirm the transaction
      const tx = await multisigData.methods
        .confirmTransaction(transactionId)
        .send({ from: address[0] });
      console.log("Transaction confirmed", tx);
      return tx;
    } catch (error) {
      console.error(error);
    }
  };
  const logout = async () => {
    await web3auth.logout();
    setProvider(null);
    setLoggedIn(false);
    dispatch(setAddress(""));
    dispatch(setProfile(null));
    console.log("logged out");
  };

  return {
    provider,
    loggedIn,
    login,
    getUserInfo,
    logout,
    web3,
    confirmTransaction,
  };
}
