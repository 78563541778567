
import "../../App.scss";
import Navigation from "../Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Card from "react-bootstrap/Card";

function ResetPassword() {
  return (
    <>
      <Navigation />
        <header className="text-center flex-column d-flex justify-content-center">
          <Container>
            <div className="text-center my-3">
              <h1 className="py-3 display-6 h1-responsive text-uppercase">
              Reset<span className="bold"> Password</span>
              </h1>
            </div>
            <div>We’ll email you instructions how to reset your password.</div>

            <Row className="py-4">
              <Col xs={12} lg={5} className="mx-auto">
                <Card>
                  <Card.Body>
                    <Form>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email address"
                        className="mb-3"
                      >
                        <Form.Control
                          type="email"
                          placeholder="name@example.com"
                        />
                      </FloatingLabel>
                      <div className="d-grid my-3">
                        <Button
                          variant="dark20"
                          size="lg"
                          href="#action"
                          type="submit"
                        >
                          Reset password
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
                <div className="m-3 alink">
                  <a href={process.env.PUBLIC_URL + "/Login"}>
                    <svg viewBox="0 0 48.08 40.9" height="17" className="me-3">
                      <use href="#arrowL"></use>
                    </svg>
                    Back to login
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
    </>
  );
}

export default ResetPassword;
