import React from 'react'
import "../../App.scss";
import TopNav from "../TopNav";
import Navigation from "../Nav";
import AccountProfil from "./AccountProfil"
import AccountWallets from "./AccountWallets"
import AccountSecurity from "./AccountSecurity"
import AccountNotifications from "./AccountNotifications"
import {Container, Row, Tabs, Tab} from "react-bootstrap";
// importing aos
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function AccountSettings() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
   <TopNav/>  
      <Navigation />
        <Container className="my-5">
          <Row>
          <div className="alink">
  <a  href={process.env.PUBLIC_URL + "/UserProfile"}>
   <svg viewBox="0 0 48.08 40.9" height="17" className="me-3">
      <use href="#arrowL"></use> 
    </svg>Back
  </a>
</div>
        <div className="text-center">
            <h1 className="display-6 h1-responsive text-uppercase">
              <span className="bold">Account </span>settings
            </h1>
           </div>
          </Row>
          <Row className="mt-4 d-flex justify-content-center">
            <Tabs defaultActiveKey="Profil" className="justify-content-center mx-auto text-center mb-3 text-dark40">
           
              <Tab eventKey="Profil" title="Profil">
                <AccountProfil/>
              </Tab>
              <Tab eventKey="Wallets" title="Wallets" >
              <AccountWallets/>
              </Tab>
              <Tab eventKey=" Security" title=" Security">
              <AccountSecurity/>
              </Tab>
              <Tab eventKey=" Notifications" title=" Notifications">
                <AccountNotifications/>
              </Tab>
              </Tabs>
              
              </Row>
        </Container>
    </>
  );
}

export default AccountSettings;
