import React from 'react'
import "../../App.scss";
import {Accordion, Row, Col, Form, Stack} from "react-bootstrap";


function AccountNotifications() {
  return (
    <>
    <Col xs={12} md={10} lg={7} xl={6} className="mx-auto">
        <Row className="mt-4 justify-content-center">
        <Accordion flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header >
        <span className="fs-5">NFTs</span>
        </Accordion.Header>
        <Accordion.Body>
        <Stack direction="horizontal" className="text-dark40" gap={4}>
                    <Form.Label className="ms-auto">
                      <small>Email</small>
                    </Form.Label>
                    <Form.Label>
                      <small>WebApp</small>
                    </Form.Label>
                  </Stack>
                  <Form>
                  <Stack direction="horizontal" gap={5}>
                   <div className="ms-auto">
                   <Form.Label className="text-dark40">
                      SELECT ALL
                    </Form.Label>
                   </div>
                   <Form.Check
                      className="me-2 pb-1"
                      aria-label="option 1"
                    />
                    <Form.Check className="pb-1" aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Sales
                      <br />
                      <small className="text-muted">
                        When one of your NFTs sells
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Successful bids
                      <br />
                      <small className="text-muted">
                        When your bid was successful and the NFT is in your
                        wallet
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Unsuccessful offers
                      <br />
                      <small className="text-muted">
                        When the NFT owner declines your offer
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Bids
                      <br />
                      <small className="text-muted">
                        When someone bids on one of your items
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Outbids
                      <br />
                      <small className="text-muted">
                        When someone outbids yours bids
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                </Form>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><span className="fs-5">Projects</span></Accordion.Header>
        <Accordion.Body>
        <Stack direction="horizontal" className="text-dark40" gap={4}>
                    <Form.Label className="ms-auto">
                      <small>Email</small>
                    </Form.Label>{" "}
                    <Form.Label>
                      <small>WebApp</small>
                    </Form.Label>
                  </Stack>
        <Form>
        <Stack direction="horizontal" gap={5}>
                   <div className="ms-auto">
                   <Form.Label className="text-dark40">
                      SELECT ALL
                    </Form.Label>
                   </div>
                   <Form.Check
                      className="me-2 pb-1"
                      aria-label="option 1"
                    />
                    <Form.Check className="pb-1" aria-label="option 2" />
                  </Stack>
                  <hr />
                   <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Project status (for get funded only)
                      <br />
                      <small className="text-muted">
                        When your applied project changes the status
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Voted project started
                      <br />
                      <small className="text-muted">
                        When the project that you voted has been start receiving
                        investments
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Invested project progress
                      <br />
                      <small className="text-muted">
                        When invested project receives full amount or you get a
                        refundr
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Replies of the projects
                      <br />
                      <small className="text-muted">
                        When founder replies to your question in a project
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      New projects
                      <br />
                      <small className="text-muted">
                        When new project appears on Tozex
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      New updates of the invested project
                      <br />
                      <small className="text-muted">
                        When founder creates a new post update of the project
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                </Form>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><span className="fs-5">Wallets & Multisignature</span></Accordion.Header>
        <Accordion.Body>
        <Stack direction="horizontal" className="text-dark40" gap={4}>
                    <Form.Label className="ms-auto">
                      <small>Email</small>
                    </Form.Label>{" "}
                    <Form.Label>
                      <small>WebApp</small>
                    </Form.Label>
                  </Stack>
                  <Stack direction="horizontal" gap={5}>
                   <div className="ms-auto">
                   <Form.Label className="text-dark40">
                      SELECT ALL
                    </Form.Label>
                   </div>
                   <Form.Check
                      className="me-2 pb-1"
                      aria-label="option 1"
                    />
                    <Form.Check className="pb-1" aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Form>
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Multisignature confirmation
                      <br />
                      <small className="text-muted">
                        When your receives a message for confirming transactions
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                  <hr />
                  <Stack direction="horizontal" gap={5}>
                    <Form.Label>
                      Received project’s tokens
                      <br />
                      <small className="text-muted">
                        When the project’s token launches and you receives token
                      </small>
                    </Form.Label>
                    <Form.Check
                      className="ms-auto me-2"
                      aria-label="option 1"
                    />
                    <Form.Check aria-label="option 2" />
                  </Stack>
                </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
              </Row>
            </Col>
    </>
  );
}

export default AccountNotifications;
