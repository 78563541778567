import React from "react";
import "../App.scss";
import {Container, Row, Col, Nav} from "react-bootstrap";

function Footer() {
  return (
    <footer className="waterproof" id="subscribe">
      <div className="d-block divise"></div>
      <Container className="pt-5">
       
        <div>
          <Row className="my-3 justify-content-center">
            <Col xs={6} md={4}>
              <Nav className="flex-column navfoot">
                <Nav.Link href="#action1">Company</Nav.Link>
                <Nav.Link href="#action2">About </Nav.Link>
                <Nav.Link href="#action3">Carreers</Nav.Link>
                <Nav.Link href="#action4">Contact Us</Nav.Link>
              </Nav>
            </Col>
            <Col xs={6} md={4}>
              <Nav className="flex-column navfoot">
                <Nav.Link href="#action5">Ressources </Nav.Link>
                <Nav.Link href="#action6">Documentation</Nav.Link>
                <Nav.Link href="#action7">Help center </Nav.Link>
                <Nav.Link   href={process.env.PUBLIC_URL + "/Faq"}>FAQ </Nav.Link>
              </Nav>
            </Col>
            <Col xs={6} md={4}>
              <Nav className="flex-column navfoot">
                <Nav.Link href="#action9">Conditions</Nav.Link>
                <Nav.Link href="#action10">Privacy Policy</Nav.Link>
                <Nav.Link href="#action11">Term of service</Nav.Link>
                <Nav.Link href="#action12">KYC & AML</Nav.Link>
              </Nav>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
          <Col xs={12} md={8} lg={6} className="mx-auto d-block">
            <p className="text-muted text-center">©2023 Tozex Inc</p>
          </Col>
        </Row>
        </div>
      </Container>
     
    </footer>
  );
}

export default Footer;
