import TopNav from "../TopNav";
import "../../App.scss";
import Nav from "../Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Card from "react-bootstrap/Card";

function SignUp() {
  return (
    <>
      <TopNav />
      <Nav />
        <header className="text-center flex-column d-flex justify-content-center">
          <Container>
            <div className="text-center my-3">
              
              <h1 className="py-3 display-6 h1-responsive text-uppercase">
                <span className="bold">Sign up</span>
              </h1>
            </div>

            <Row className="pb-4">
              <Col xs={12} lg={5} className="mx-auto">
                <Card>
                  <Card.Body>
                    <div className="d-grid gap-3">
                      <Button variant="outline-dark" size="lg">
                        <svg
                          className="me-2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 60 60"
                          height="35px"
                        >
                          <path
                            fill="currentcolor"
                            d="M50,30.46a23.52,23.52,0,0,0-.37-4.17H30.41V34.2h11a9.4,9.4,0,0,1-4,6.1h0A12.27,12.27,0,0,1,19,33.9h0a11.91,11.91,0,0,1,0-7.76h0a12.19,12.19,0,0,1,11.44-8.41,11.17,11.17,0,0,1,7.82,3l5.85-5.85A19.64,19.64,0,0,0,30.41,9.57,20.4,20.4,0,0,0,12.17,39.16h0A20.43,20.43,0,0,0,30.41,50.43a19.57,19.57,0,0,0,13.57-5h0C47.81,41.88,50,36.66,50,30.46Z"
                          />
                        </svg>
                        Continue with Google
                      </Button>
                      <Button variant="outline-dark" size="lg">
                        <svg
                          className="me-2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 60 60"
                          height="35px"
                        >
                          <path
                            fill="currentcolor"
                            d="M47.57,22.67a10.44,10.44,0,0,0-5,8.78,10.16,10.16,0,0,0,6.18,9.32,23.73,23.73,0,0,1-3.16,6.54c-2,2.84-4,5.68-7.17,5.68s-3.94-1.82-7.56-1.82-4.78,1.88-7.65,1.88-4.87-2.63-7.17-5.86a28.25,28.25,0,0,1-4.81-15.26c0-9,5.82-13.72,11.56-13.72,3,0,5.58,2,7.5,2s4.66-2.13,8.12-2.13a10.87,10.87,0,0,1,9.15,4.58ZM36.78,14.3a10.31,10.31,0,0,0,2.45-6.42A4.45,4.45,0,0,0,39.14,7a10.33,10.33,0,0,0-6.78,3.5,10,10,0,0,0-2.54,6.24,4.12,4.12,0,0,0,.09.84,3.24,3.24,0,0,0,.62.06,8.91,8.91,0,0,0,6.25-3.29Z"
                          />
                        </svg>
                        Continue with Apple
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <div className="my-4">or</div>
                <Card>
                  <Card.Body>
                    <Form>
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email address"
                        className="mb-3"
                      >
                        <Form.Control
                          type="email"
                          placeholder="name@example.com"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingPassword"
                        label="Password"
                      >
                        <Form.Control type="password" placeholder="Password" />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingPassword"
                        label="Confirm Password"
                      >
                        <Form.Control type="password" placeholder="Password" />
                      </FloatingLabel>
                      <div className="d-grid my-3">
                        <Button
                          variant="dark20"
                          size="lg"
                          href="#action1"
                          type="submit"
                        >
                          Register
                        </Button>
                      </div>
                      <Form.Text className="text-muted">
                        By creating an account, I agree to Tozex’s Terms and
                        Conditions and Privacy Policy
                      </Form.Text>
                    </Form>
                  </Card.Body>
                </Card>
                <div className="my-3 alink">
                  <span className="text-muted me-2">Already have an account? </span>
                  <a href={process.env.PUBLIC_URL + "/Login"}> Login</a>
                  <svg className="ms-1" viewBox="0 0 48.08 40.9" height="14">
                    <polygon
                      fill="currentcolor"
                      points="26.09 0 22.68 3.66 38.04 17.95 0 17.95 0 22.94 38.05 22.95 22.68 37.24 26.09 40.9 48.08 20.45 26.09 0"
                    />
                  </svg>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
    </>
  );
}

export default SignUp;
