import React from 'react'
import "../../App.scss";
import {Container, Row, Table } from "react-bootstrap";

function ResponsiveTab() {
  return (
    <Container>
      <Row>
        <Table responsive>
          <thead>
            <tr>
              <th>Action</th>
              <th>Browser name</th>
              <th>IP address</th>
              <th>Device type</th>
              <th>Log at</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Login</td>
              <td>Chrome</td>
              <td>2a02:a31d:8540:ba80:fc43:a6e6:45c3:41f7, 172.69.22.154</td>
              <td>browser</td>
              <td>2022-11-02, 08:31:48</td>
            </tr>
            <tr>
              <td>Login</td>
              <td>Chrome</td>
              <td>2a02:a31d:8540:ba80:fc43:a6e6:45c3:41f7, 172.69.22.154</td>
              <td>browser</td>
              <td>2022-11-02, 08:31:48</td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Container>
  );
}

export default ResponsiveTab;
