import {
  setDestination,
  setTokenStandard,
  setToken,
  setTokenId,
  setValue,
} from "../reduces/createTransactionReducer";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const useCreateTransactionHelper = () => {
  const destination = useSelector(
    (state) => state.transaction.createTransaction.destination
  );
  const createTransactionObj = useSelector(
    (state) => state.transaction.createTransaction
  );
  const token = useSelector(
    (state) => state.transaction.createTransaction.token
  );
  const tokenStandard = useSelector(
    (state) => state.transaction.createTransaction.tokenStandard
  );
  const tokenId = useSelector(
    (state) => state.transaction.createTransaction.tokenId
  );
  const value = useSelector(
    (state) => state.transaction.createTransaction.value
  );
  const dispatch = useDispatch();
  const setLocalDestination = (value) => {
    dispatch(setDestination(value));
  };
  const setLocalToken = (value) => {
    dispatch(setToken(value));
  };
  const setLocalTokenStandard = (value) => {
    dispatch(setTokenStandard(value));
  };
  const setLocalTokenId = (value) => {
    dispatch(setTokenId(value));
  };
  const setLocalValue = (value) => {
    dispatch(setValue(value));
  };

  return {
    destination,
    token,
    tokenStandard,
    tokenId,
    createTransactionObj,
    value,
    setLocalDestination,
    setLocalToken,
    setLocalTokenStandard,
    setLocalTokenId,
    setLocalValue,
  };
};

export default useCreateTransactionHelper;
