import { Container, Row, Card, Col } from "react-bootstrap";
import { useEffect } from "react";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function Blockchains() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Container className="anchor my-5" id="blockchains">
        <div className="text-center my-5">
          <h1
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
            className="py-3 h1-responsive text-uppercase"
          >
            Supported<span className="bold"> Blockchains</span>
          </h1>
        </div>
        <Row className="text-center mx-auto d-flex flex-row justify-content-center align-items-center">
          <Col sm={6} md={3} className="mb-3">
            <Card>
              <div className="pt-3">
                <svg viewBox="0 0 60 60" height="100">
                  <use href="#Etherum"></use>
                </svg>
              </div>
              <Card.Body>Ethereum</Card.Body>
            </Card>
          </Col>

          <Col sm={6} md={3} className="mb-3">
            <Card>
              <div className="pt-3">
                <svg viewBox="0 0 60 60" height="100">
                  <use href="#BNB"></use>
                </svg>
              </div>
              <Card.Body>BNB Chain</Card.Body>
            </Card>
          </Col>

          <Col sm={6} md={3} className="mb-3">
            <Card>
              <div className="pt-3">
                <svg viewBox="0 0 60 60" height="100">
                  <use href="#Polygon"></use>
                </svg>
              </div>
              <Card.Body>Polygon</Card.Body>
            </Card>
          </Col>

          <Col sm={6} md={3} className="mb-3">
            <Card>
              <div className="pt-3">
                <svg viewBox="0 0 60 60" height="100">
                  <use href="#Avalanche"></use>
                </svg>
              </div>
              <Card.Body>Avalanche</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <hr />
    </>
  );
}

export default Blockchains;
