import "../../App.scss";
import TopNav from "../TopNav";
import {
  Container,
  OverlayTrigger,
  Tooltip,
  Form,
  Row,
  Col,
  Stack,
  Card,
  Button,
  InputGroup,
} from "react-bootstrap";
import Nav from "../Nav";
import Footer from "../Footer";

import { useUserHelper } from "../../infra/hooks/userUserHelper";
import useCreateContractHelper from "../../infra/hooks/useCreateContractHelper";
import {
  useCreateContractMutation,
  useGetContractsQuery,
} from "../../infra/services/contracts";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

function CreateSign() {
  const { address } = useUserHelper();
  const {
    name,
    signers,
    createWalletObj,
    setLocalName,
    setLocalNetwork,
    addLocalSigner,
    setLocalSigner,
    setLocalRequired,
  } = useCreateContractHelper();
  const [createContract, { data, isLoading, isError, isSuccess }] =
    useCreateContractMutation();

  const { refetch } = useGetContractsQuery();

  console.log(
    JSON.stringify(data),
    "OUTSIDE CALLBACK isLoading and other data is working:"
  );

  const invokeCreateWallet = async () => {
    if (signers.length < createWalletObj.required) {
      toast.error(
        "Number of signers must be greater than or equal to required"
      );
      return;
    } else if (
      signers.filter((item, index) => signers.indexOf(item) !== index).length >
      0
    ) {
      toast.error("Duplicate signers not allowed");
      return;
    }
    createContract(createWalletObj)
      .unwrap()
      .then(async () => {
        refetch();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Multisig vault created successfully");
    }
    if (isError) {
      toast.error("Error creating Multisig wallet");
    }
  }, [isSuccess, isError]);
  return (
    <>
      <TopNav />
      <Nav />
      <div>
        <header className="py-5  flex-column d-flex justify-content-center">
          <Container>
            <Row>
              <Col xs={12} md={9} lg={6} className="mx-auto">
                <div className="m-3 alink">
                  <Link to={process.env.PUBLIC_URL + "/UserProfile"}>
                    <svg viewBox="0 0 48.08 40.9" height="17" className="me-3">
                      <use href="#arrowL"></use>
                    </svg>
                    Back
                  </Link>
                </div>

                <div className="mt-2">
                  <h3 className="mb-3">Create Digital Vault Multi Signature</h3>

                  <Form onSubmit={(event) => event.preventDefault()}>
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        onChange={(e) => setLocalName(e.target.value)}
                        value={name}
                        type="name"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formNetwork">
                      <Form.Label>Blockchain network</Form.Label>
                      <Form.Select
                        onChange={(e) => setLocalNetwork(e.target.value)}
                        aria-label="Network"
                      >
                        <option>Etherum</option>
                        <option value="bsc_mainnet">BSC Mainnet</option>
                        <option value="ethereum_sepolia">
                          Ethereum Sepolia
                        </option>
                        <option value="polygone_testnet">
                          Polygon testnet
                        </option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form">
                      <Form.Label>Co signer's wallets</Form.Label>
                      {signers.map((signer, index) => (
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            className="me-auto"
                            placeholder="1."
                            onChange={(e) =>
                              setLocalSigner(index, e.target.value)
                            }
                            value={signer}
                          />
                          {index === signers.length - 1 && (
                            <Button
                              variant="outline-dark"
                              id="input-group-"
                              align="end"
                              onClick={() => addLocalSigner()}
                            >
                              Add Co signer
                            </Button>
                          )}
                        </InputGroup>
                      ))}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="form">
                      <Form.Label>Invite co signers by email</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type="text"
                          className="me-auto"
                          placeholder="@"
                        />

                        <Button
                          variant="outline-dark"
                          id="input-group-"
                          align="end"
                        >
                          Invite Co signer
                        </Button>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formNumbers">
                      <Form.Label>Requested number of co signers</Form.Label>
                      <Form.Select
                        onChange={(e) =>
                          setLocalRequired(parseInt(e.target.value))
                        }
                        aria-label="Numbers"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="2">3</option>
                        <option value="2">4</option>
                      </Form.Select>
                      <Form.Text className="text-muted">
                        The minimum granted co signers requiered to execute a
                        transfer
                      </Form.Text>
                    </Form.Group>
                    <Button
                      variant="multisig"
                      onClick={() => invokeCreateWallet()}
                      type="submit"
                      className="my-3 btn-shadow-multisig text-uppercase"
                    >
                      {isLoading ? (
                        <div class="spinner-border" role="status">
                          <span class="sr-only"></span>
                        </div>
                      ) : (
                        "Deploy contract"
                      )}
                    </Button>
                  </Form>
                </div>
              </Col>
              <Col xs={12} md={9} lg={5} className="mt-lg-5 mx-auto">
                <Card className="mt-2">
                  <Card.Body className="text-start">
                    <Stack direction="horizontal" gap={2}>
                      <Form.Control
                        plaintext
                        className="text-light"
                        readOnly
                        defaultValue={address}
                      />
                      <div className="text-nowrap text-muted ms-auto">
                        <small>Ethereum Mainnet</small>
                      </div>
                    </Stack>
                    <hr />
                    <Stack direction="horizontal" gap={2}>
                      <div>
                        {" "}
                        <OverlayTrigger
                          overlay={(props) => (
                            <Tooltip {...props}>Copy address</Tooltip>
                          )}
                          placement="bottom"
                        >
                          <Button size="sm" variant="light">
                            {" "}
                            <svg viewBox="0 0 127.56 127.56" height="20">
                              <use href="#Copy"></use>{" "}
                            </svg>
                          </Button>
                        </OverlayTrigger>
                      </div>
                      <div className="ms-auto">
                        <Button size="sm" variant="light">
                          Disconnect
                        </Button>
                      </div>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </header>
      </div>
      <Footer />
    </>
  );
}

export default CreateSign;
