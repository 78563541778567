import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getAccessToken } from "../utils";

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'same-origin',
    prepareHeaders: (headers, { getState }) => {
      let token = getState().user.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }else{
        token = getAccessToken();
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });