import React from "react";
import "../../App.scss";
import { useState, useEffect } from "react";
import {
  Container,
  Row,
  Table,
  Button,
  Collapse,
  Col,
  Card,
  Form,
} from "react-bootstrap";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const ResponsiveTab = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const [open, setOpen] = useState(false);

  const [displayedDivA, setDisplayedDivA] = useState("divA1");
  const switchDivA = () => {
    if (displayedDivA === "divA1") {
      setDisplayedDivA("divA2");
    } else {
      setDisplayedDivA("divA1");
    }
  };
  const [displayedDivB, setDisplayedDivB] = useState("divB1");
  const switchDivB = () => {
    if (displayedDivB === "divB1") {
      setDisplayedDivB("divB2");
    } else {
      setDisplayedDivB("divB1");
    }
  };
  const [displayedDivC, setDisplayedDivC] = useState("divC1");
  const switchDivC = () => {
    if (displayedDivC === "divC1") {
      setDisplayedDivC("divC2");
    } else {
      setDisplayedDivC("divC1");
    }
  };
  const [displayedDivD, setDisplayedDivD] = useState("divD1");
  const switchDivD = () => {
    if (displayedDivD === "divD1") {
      setDisplayedDivD("divD2");
    } else {
      setDisplayedDivD("divD1");
    }
  };

  return (
    <>
      <Container className="mt-5 anchor " id="Pricing">
        <Row className="py-2 d-flex flex-row">
          <div className="text-center">
            <h1 className="py-3 h1-responsive text-uppercase">Pricing</h1>
          </div>
        </Row>
        <Row className="text-center mx-auto d-flex flex-row justify-content-center ">
          <Col sm={12} md={6} lg={3} className="mb-3">
            <Card
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-delay="0"
              data-aos-easing="ease-in-out"
              className="h-100"
            >
              <Card.Header>
                <h4 className="border-3 border-bottom border-first py-2">
                  FREE
                </h4>
              </Card.Header>
              <Row>
                <div className="d-flex flex-column justify-content-center ">
                  <div className="d-flex mx-auto mt-3 text-center customswitch">
                    <small className="text-muted mx-2">Monthly</small>
                    <Form.Check
                      type="switch"
                      onClick={switchDivA}
                      variant="primary"
                    />
                    <small className="mx-2 text-muted">Yearly</small>
                  </div>
                  <div className="text-center mx-auto">
                    {displayedDivA === "divA1" && (
                      <div className="d-flex mx-auto mt-3">
                        <span className="price"></span>
                        <span className="sign">€</span>
                        <span className="currency">0</span>
                        <span className="month"> MONTH</span>{" "}
                      </div>
                    )}

                    {displayedDivA === "divA2" && (
                      <div className="text-center d-flex mx-auto mt-3">
                        <span className="price"></span>
                        <span className="sign">€</span>
                        <span className="currency">0</span>
                        <span className="month">YEAR</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
              <Card.Body>
                <div className="mt-3 text-center mx-auto">
                  <div className="alink">
                    <Link to={process.env.PUBLIC_URL + "/Login"}>
                      <span className="text-first">
                        <h5>
                          <svg
                            viewBox="0 0 48.08 40.9"
                            height="15"
                            className="me-2"
                          >
                            <use href="#icon-flecheUR"></use>
                          </svg>
                          START FREE
                        </h5>{" "}
                      </span>
                    </Link>
                  </div>
                </div>
                <hr />
                <div className="ver_mas text-center text-muted">
                  <small>
                    Designed for individuals to quickly use our Interface to
                    manage their Multisig Vault.
                  </small>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={6} lg={3} className="mb-3">
            <Card
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="1"
              data-aos-easing="ease-in-out"
              className="h-100"
            >
              <Card.Header>
                <h4 className="h4-responsive border-3 border-bottom border-second py-2">
                  BUILDER
                </h4>
              </Card.Header>
              <Row>
                <div className="d-flex flex-column justify-content-center ">
                  <div className="d-flex mx-auto mt-3 text-center customswitch">
                    <small className="text-muted mx-2">Monthly</small>
                    <Form.Check
                      type="switch"
                      onClick={switchDivB}
                      variant="primary"
                    />
                    <small className="mx-2 text-muted">Yearly</small>
                  </div>
                  <div className="text-center mx-auto">
                    {displayedDivB === "divB1" && (
                      <div className="d-flex mx-auto mt-3">
                        <span className="price"></span>
                        <span className="sign">€</span>
                        <span className="currency">90</span>
                        <span className="month"> MONTH</span>{" "}
                      </div>
                    )}

                    {displayedDivB === "divB2" && (
                      <div className="text-center d-flex mx-auto mt-3">
                        <span className="price"></span>
                        <span className="sign">€</span>
                        <span className="currency">990</span>
                        <span className="month">YEAR</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
              <Card.Body>
                <div className="mt-3 text-center mx-auto">
                  <div className="alink">
                    <a href={process.env.PUBLIC_URL + "/Login"}>
                      <span className="text-second">
                        <h5>
                          <svg
                            viewBox="0 0 48.08 40.9"
                            height="15"
                            className="me-2"
                          >
                            <use href="#icon-flecheUR"></use>
                          </svg>{" "}
                          <Link to={process.env.PUBLIC_URL + "/Login"}>
                            START BUILDER
                          </Link>
                        </h5>
                      </span>
                    </a>
                  </div>
                </div>
                <hr />
                <div className="ver_mas text-center text-muted">
                  <small>
                    Designed for individuals and early stage WEB3 company to
                    quickly use our Interface to manage their Multisig Vault.
                  </small>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={6} lg={3} className="mb-3">
            <Card
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-delay="1"
              data-aos-easing="ease-in-out"
              className="h-100"
            >
              <Card.Header>
                <h4 className="border-3 border-bottom border-third py-2">
                  <Link to={process.env.PUBLIC_URL + "/Login"}>BUSINESS</Link>
                </h4>
              </Card.Header>
              <Row>
                <div className="d-flex flex-column justify-content-center ">
                  <div className="d-flex mx-auto mt-3 text-center customswitch">
                    <small className="text-muted mx-2">Monthly</small>
                    <Form.Check
                      type="switch"
                      onClick={switchDivC}
                      variant="primary"
                    />
                    <small className="mx-2 text-muted">Yearly</small>
                  </div>
                  <div className="text-center mx-auto">
                    {displayedDivC === "divC1" && (
                      <div className="d-flex mx-auto mt-3">
                        <span className="price"></span>
                        <span className="sign">€</span>
                        <span className="currency">290</span>
                        <span className="month"> MONTH</span>{" "}
                      </div>
                    )}

                    {displayedDivC === "divC2" && (
                      <div className="text-center d-flex mx-auto mt-3">
                        <span className="price"></span>
                        <span className="sign">€</span>
                        <span className="currency">2900</span>
                        <span className="month">YEAR</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
              <Card.Body>
                <div className="mt-3 text-center mx-auto">
                  <div className="alink">
                    <a href={process.env.PUBLIC_URL + "/Login"}>
                      <span className="text-third">
                        <h5>
                          <svg
                            viewBox="0 0 48.08 40.9"
                            height="15"
                            className="me-2"
                          >
                            <use href="#icon-flecheUR"></use>
                          </svg>{" "}
                          <Link to={process.env.PUBLIC_URL + "/Login"}>
                            START BUSINESS
                          </Link>
                        </h5>
                      </span>
                    </a>
                  </div>
                </div>
                <hr />
                <div className="ver_mas text-center text-muted">
                  <small>
                    Designed for companies that need to deploy and manage their
                    Multisig Vault using the API plugged to their system.
                  </small>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={12} md={6} lg={3} className="mb-3">
            <Card
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="1"
              data-aos-easing="ease-in-out"
              className="h-100"
            >
              <Card.Header>
                <h4 className="border-3 border-bottom border-multi40 py-2">
                  <Link to={process.env.PUBLIC_URL + "/Login"}>ENTERPRISE</Link>
                </h4>
              </Card.Header>
              <Row>
                <div className="d-flex flex-column justify-content-center ">
                  <div className="d-flex mx-auto mt-3 text-center customswitch">
                    <small className="text-muted mx-2">Monthly</small>
                    <Form.Check
                      type="switch"
                      onClick={switchDivD}
                      variant="primary"
                    />
                    <small className="mx-2 text-muted">Yearly</small>
                  </div>
                  <div className="text-center mx-auto">
                    {displayedDivD === "divD1" && (
                      <div className="d-flex mx-auto mt-3">
                        <span className="currency">Custom</span>
                      </div>
                    )}
                    {displayedDivD === "divD2" && (
                      <div className="d-flex mx-auto mt-3">
                        <span className="currency">Custom</span>
                      </div>
                    )}
                  </div>
                </div>
              </Row>
              <Card.Body>
                <div className="mt-3 text-center mx-auto">
                  <div className="alink">
                    <a href={process.env.PUBLIC_URL + "/Login"}>
                      <span className="text-multi40">
                        <h5>
                          <svg
                            viewBox="0 0 48.08 40.9"
                            height="15"
                            className="me-2"
                          >
                            <use href="#icon-flecheUR"></use>
                          </svg>{" "}
                          CONTACT US
                        </h5>
                      </span>
                    </a>
                  </div>
                </div>
                <hr />
                <div className="ver_mas text-center text-muted">
                  <small>
                    Designed for companies that need to deploy and manage their
                    Multisig Vault using the API plugged to their system with
                    custom features and support.
                  </small>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="py-3" id="PricingTable">
          <Button
            variant="light"
            onClick={() => setOpen(!open)}
            aria-controls="details"
            aria-expanded={open}
          >
            DETAILS
          </Button>
          <Collapse in={open}>
            <div id="details">
              <Table responsive className="align-middle table-sm text-center">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-first">FREE</th>
                    <th className="text-second">BUILDER</th>
                    <th className="text-third">BUSINESS</th>
                    <th className="text-multi40">ENTERPRISE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {" "}
                    <th className="text-start ">Application Management </th>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Max Number of Co Signers
                    </td>
                    <td className="text-first">10</td>
                    <td className="text-second">10</td>
                    <td className="text-third">10</td>
                    <td className="text-multi40">Unlimited</td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Co signer Self Custody Wallet
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Number of Multisig Vaults{" "}
                    </td>
                    <td className="text-first">2</td>
                    <td className="text-second">10</td>
                    <td className="text-third">10</td>
                    <td className="text-multi40">Unlimited</td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Remove or Replace Co Signer's Wallet
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Transaction limit (max spending amount per co signer, max
                      amount by outside transaction…
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">On demand</td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Transaction History Spreadsheet
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">Simulation mode</td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Optional KYC of Co signers
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">On demand</td>
                  </tr>

                  <tr>
                    {" "}
                    <th className="text-start">Eligible Assets</th>
                  </tr>

                  <tr>
                    <td className="text-start title">Stablecoins</td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Fungible Token (ERC20){" "}
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">NFT (ERC721 & ERC1155)</td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Native Token supported (ETH, AVAX, BNB)
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">Tokenized assets (RWA)</td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    {" "}
                    <th className="text-start">Supported Networks</th>
                  </tr>

                  <tr>
                    <td className="text-start title">Public Blockchains </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">Private Blockchains</td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">On demand</td>
                  </tr>

                  <tr>
                    {" "}
                    <th className="text-start">Security </th>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Multisig Vault Code Upgrade
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Audited Multisig Vault{" "}
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Detection of fraudulent wallet (Wallet screening)
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Wallet Connect (Metamask, Ledger…)
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    {" "}
                    <th className="text-start">Client support</th>
                  </tr>

                  <tr>
                    <td className="text-start title">Community Support </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">Technical Support</td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">Compliant Support</td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    {" "}
                    <th className="text-start">API services</th>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Up to 20K Requests / month
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">Custom</td>
                  </tr>

                  <tr>
                    <td className="text-start title">Mulsig Vault Creation</td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">10</td>
                    <td className="text-multi40">Unlimited</td>
                  </tr>

                  <tr>
                    <td className="text-start title">Submit Transaction</td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Transfert Multisig Vault Ownership
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Modification of Co Signer
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Gas limit per transaction
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">Up to 80 Gwei</td>
                    <td className="text-multi40">Custom</td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Dedicated gas fee wallet
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                    <td className="text-multi40">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#Check"></use>
                      </svg>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start title">
                      Transaction Rate limit per minute
                    </td>
                    <td className="text-first">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-second">
                      <svg viewBox="0 0 128 128" height="25">
                        <use href="#IconCross"></use>
                      </svg>
                    </td>
                    <td className="text-third">5</td>
                    <td className="text-multi40">Custom</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td className="py-2">
                      <div className="alink">
                        <a href={process.env.PUBLIC_URL + "/Login"}>
                          <span className="text-first">
                            <h6>START FREE</h6>
                          </span>
                        </a>
                      </div>
                    </td>
                    <td className="py-2">
                      <div className="alink">
                        <a href={process.env.PUBLIC_URL + "/Login"}>
                          <span className="text-second">
                            <h6>START BUILDER</h6>
                          </span>
                        </a>
                      </div>
                    </td>
                    <td className="py-2">
                      <div className="alink">
                        <a href={process.env.PUBLIC_URL + "/Login"}>
                          <span className="text-third">
                            <h6>START BUSINESS</h6>
                          </span>
                        </a>
                      </div>
                    </td>
                    <td className="py-2">
                      <div className="alink">
                        <a href={process.env.PUBLIC_URL + "/Login"}>
                          <span className="text-multi40">
                            <h6>CONTACT US</h6>
                          </span>
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Collapse>
        </Row>
      </Container>
    </>
  );
};

export default ResponsiveTab;
