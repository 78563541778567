import { Container, Row, Card, Col } from "react-bootstrap";
import { useEffect } from "react";
import EthC from "../../media/ColorEthC.svg";
import Avax from "../../media/ColorAvax.svg";
import Aave from "../../media/ColorAave.svg";
import LinkC from "../../media/ColorLink.svg";
import EuroC from "../../media/ColorEuroC.svg";
import Paxg from "../../media/ColorPaxg.svg";
import Usdt from "../../media/ColorUsdt.svg";
import Usdtg from "../../media/ColorUsdtg.svg";
// importing aos
import AOS from "aos";
import "aos/dist/aos.css";

function Assets() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Container className="anchor my-5" id="Assets">
        <div className="text-center my-5">
          <h1
            data-aos="zoom-in-right"
            data-aos-duration="1200"
            data-aos-delay="0"
            data-aos-easing="ease-in-out"
            className="py-3 h1-responsive text-uppercase"
          >
            Supported<span className="bold"> Assets & Networks</span>
          </h1>
        </div>
        <Row className="text-center mx-auto d-flex flex-row justify-content-center align-items-center">
          <Col sm={6} md={3} className="mb-3">
            <Card>
              <div className="d-flex justify-content-center pt-3">
                <img className="mx-2" alt="User" src={EthC} height="60px" />
                <img className="mx-2" alt="User" src={Avax} height="60px" />
              </div>
              <Card.Body>Native Tokens</Card.Body>
            </Card>
          </Col>

          <Col sm={6} md={3} className="mb-3">
            <Card>
              <div className="d-flex justify-content-center pt-3">
                <img className="mx-2" alt="User" src={Aave} height="60px" />
                <img className="mx-2" alt="User" src={LinkC} height="60px" />
              </div>
              <Card.Body>Fungible Tokens</Card.Body>
            </Card>
          </Col>

          <Col sm={6} md={3} className="mb-3">
            <Card>
              <div className="d-flex justify-content-center pt-3">
                <img className="mx-2" alt="User" src={Usdt} height="60px" />
                <img className="mx-2" alt="User" src={EuroC} height="60px" />
              </div>
              <Card.Body>Stabelcoins</Card.Body>
            </Card>
          </Col>

          <Col sm={6} md={3} className="mb-3">
            <Card>
              <div className="d-flex justify-content-center pt-3">
                <img className="mx-2" alt="User" src={Usdtg} height="60px" />
                <img className="mx-2" alt="User" src={Paxg} height="60px" />
              </div>
              <Card.Body>RWA</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Assets;
