import "../App.scss";
import React from "react";
import { useEffect, useState } from "react";
import {
  Container,
  Button,
  Nav,
  Navbar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useWeb3Helper } from "../infra/hooks/web3Helper";
import { useNavigate } from "react-router-dom";

function TopNav() {
  const { login, loggedIn, logout } = useWeb3Helper();
  const navigate = useNavigate();

  const [theme, setTheme] = useState(
    localStorage.getItem("theme") || "theme-dark"
  );
  const toggleTheme = () => {
    if (theme === "theme-dark") {
      setTheme("theme-light");
    } else {
      setTheme("theme-dark");
    }
  };
  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.body.className = theme;
  }, [theme]);

  const localLogOut = async () => {
    await logout();
    navigate(`/`);
  };
  const localLogIn = async () => {
    const result = await login();
    if(result){
      navigate(`/UserProfile`);
    }
  };

  return (
    <>
      <Navbar>
        <Container className="first-navbar d-flex justify-content-end">
          <Nav>
            <OverlayTrigger
              overlay={(props) => <Tooltip {...props}>Dark / Light</Tooltip>}
              placement="bottom"
            >
              <Button
                className="rounded-circle btn-switch me-3 mt-1"
                onClick={toggleTheme}
              ></Button>
            </OverlayTrigger>
            {!loggedIn ? (
              <Button variant="light" onClick={() => localLogIn()}>
                Connect Wallet
              </Button>
            ) : (
              <Button  variant="light"    size="sm" onClick={() => localLogOut()}>
                logout
              </Button>
            )}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default TopNav;
