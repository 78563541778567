import React from 'react'
import "../../App.scss";
import {Container, Row, Button, Col, Table, Card, Tab, Tabs } from "react-bootstrap";
import ProjectsHistory from "./ProjectsHistoryTable";
import NFTsHistory from "./NFTsHistoryTable";

function AccountWallets() {
  return (
    <>
        <Col xs={12} md={10} lg={7} xl={6} className="mx-auto">
        <Row className="mt-4 justify-content-center">
            <Card>
            <Card.Body>
                      <Row className="align-items-center text-center">
                          <h4>Associated wallets</h4>
                    <div> You should have at least one connected wallet
                    </div>
                    <div><Button className="btn-shadow-dark20 text-uppercase mt-3 " variant="dark20">
                    Connect new wallet
                      </Button>    </div>
                  </Row>
                  </Card.Body>
                </Card>
              <Row className="pt-4">
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Wallet address</th>
                      <th>Network</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0xA91...aa2E9</td>
                      <td>Ethereum</td>
                      <td>
                        <svg viewBox="0 0 18.41 68.08" height="17">
                          <use href="#Points"></use>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              </Row>
              </Col>
      <hr />
      <Container>
        <div className="text-center">
          <h3 className="pt-4 h3-responsive text-uppercase">
            Transaction history
          </h3>
        </div>
        <div className="mb-5">
          <Tabs defaultActiveKey="Projects" id="history" className='text-dark40'>
            <Tab eventKey="Projects" title="Projects">
              <ProjectsHistory />
            </Tab>
            <Tab eventKey="NFTs" title="NFTs">
              <NFTsHistory />
            </Tab>
          </Tabs>
        </div>
      </Container>
    </>
  );
}

export default AccountWallets;
