import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import useCreateTransactionHelper from "../../infra/hooks/useCreateTransactionHelper ";

import { useEffect } from "react";
import {
  useGetTransactionsQuery,
  useCreateTransactionMutation,
} from "../../infra/services/transactions";
import { toast } from "react-toastify";
import useCreateContractHelper from "../../infra/hooks/useCreateContractHelper";
/*
 * Renders a modal component for submitting a transaction.
 * @returns {JSX.Element} The SubmitTx component.
 */
export function SubmitTx({ show, handleClose, handleShow, ...props }) {
  const {
    destination,
    value,
    createTransactionObj,
    setLocalDestination,
    setLocalToken,
    setLocalTokenStandard,
    setLocalValue,
  } = useCreateTransactionHelper();
  const { currentContract } = useCreateContractHelper();
  const [createTransaction, { isLoading, isError, isSuccess }] =
    useCreateTransactionMutation();
  const { refetch } = useGetTransactionsQuery({
    contractAddress: currentContract?.contract_address,
    network: currentContract?.network,
  });

  const invokeCreateTransaction = async () => {
    // Sleep for 2 seconds
    try {
      createTransaction({
        ...createTransactionObj,
        contractAddress: currentContract?.contract_address,
        network: currentContract?.network,
        data: "0x",
        confirmTimestamp: 0,
      })
        .unwrap()
        .then(async () => {
          refetch();
          handleClose()
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      toast.error("Error creating transaction");
      console.log(e);
      handleClose()
    }
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Transaction created successfully");
    }
    if (isError) {
      toast.error("Error creating transaction");
    }
  }, [isSuccess, isError]);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      {...props}
      aria-labelledby="Import"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="Import">Submit transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <Form onSubmit={(event) => event.preventDefault()}>
          <Form.Group className="py-3" controlId="formAdress">
            <Form.Label>Destination wallet address</Form.Label>
            <Form.Control
              value={destination}
              onChange={(e) => setLocalDestination(e.target.value)}
              type="name"
            />
          </Form.Group>
          <Form.Group className="py-3" controlId="formName">
            <InputGroup className="mb-3">
              <Form.Select
                onChange={(e) =>
                  setLocalTokenStandard(parseInt(e.target.value))
                }
                aria-label="Default select example"
              >
                <option>Select your token category</option>
                <option value="0">ERC20</option>
                <option value="1">ERC721</option>
                <option value="2">ERC1155</option>
                <option value="3">Native</option>
              </Form.Select>
            </InputGroup>
          </Form.Group>
          <Form.Group className="py-3" controlId="formName">
            <InputGroup className="mb-3">
              <Form.Select
                onChange={(e) => setLocalToken(e.target.value)}
                aria-label="Default select example"
              >
                <option>Select your token</option>
                <option value="0x0000000000000000000000000000000000000000">
                  1
                </option>
              </Form.Select>

              <Button
                onClick={handleShow}
                variant="outline-dark"
                id="input-group-"
                align="end"
              >
                Import
              </Button>
            </InputGroup>
          </Form.Group>
          <Form.Group className="py-3" controlId="formValue">
            <Form.Label>Amount to transfer (ETH)</Form.Label>
            <Form.Control
              value={value}
              onChange={(e) => setLocalValue(parseInt(e.target.value))}
              setLocalToken
              type="name"
            />
          </Form.Group>
          <Button
            variant="multisig"
            type="submit"
            onClick={() => invokeCreateTransaction()}
            className="my-3 btn-shadow-multisig text-uppercase"
          >
            {isLoading ? (
              <div class="spinner-border" role="status">
                <span class="sr-only"></span>
              </div>
            ) : (
              "Submit TX"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
