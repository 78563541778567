import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentNetwork: null,
  currentContract: null,
  createContract:{
    name: "",
    network: "ethereum",
    signers: [""],
    required: 1,
  }
};

const createWalletSlice = createSlice({
  name: "createContract",
  initialState,
  reducers: {
    setName: (state, action) => {
      state.createContract.name = action.payload;
    },
    setNetwork: (state, action) => {
      state.createContract.network = action.payload;
    },
    addSigner: (state) => {
      state.createContract.signers.push("");
    },
    setCurrentContract: (state, action) => {
      state.currentContract = action.payload;
    },
    setSigner: (state, action) => {
        state.createContract.signers[action.payload.index] = action.payload.value;
    },
    removeSigner: (state, action) => {
      state.createContract.signers.splice(action.payload, 1);
    },
    setRequired: (state, action) => {
      state.createContract.required = action.payload;
    },
  }
});

export const {
  setName,
  setNetwork,
  addSigner,
  setSigner,
  removeSigner,
  setRequired,
  setCurrentContract
} = createWalletSlice.actions;

export default createWalletSlice.reducer;